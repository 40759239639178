import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';

import { getDiscountRewardConfig } from './getDiscountRewardConfig';

export function calculateMissingRewardPointsAmount(
  activeRewards: LoyaltyReward[],
  loyaltyAccount?: LoyaltyAccount,
): number {
  const userPointsAmount = loyaltyAccount?.points?.balance ?? 0;

  // FIXME: Once coupon rewards are supported we should look at all rewards, not just restaurant discount.
  const discountReward = activeRewards.find(({ type }) => type === RewardType.DISCOUNT_AMOUNT);
  const { costInPoints } = getDiscountRewardConfig(discountReward, loyaltyAccount);
  const requiredPoints = costInPoints;

  return Math.max(requiredPoints - userPointsAmount, 0);
}
