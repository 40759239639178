import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getLocale } from './getLocale';
import { DEFAULT_CURRENCY } from '../constants';

export function createCurrencyFormatter(flowAPI: PlatformControllerFlowAPI) {
  const { controllerConfig, getCurrencyFormatter } = flowAPI;
  const { wixCodeApi } = controllerConfig;
  const locale = getLocale(flowAPI);
  const currency = wixCodeApi.site.currency ?? DEFAULT_CURRENCY;

  const formatCurrency = getCurrencyFormatter({
    language: locale,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  return (value: number) => {
    return formatCurrency({ value, currency });
  };
}
