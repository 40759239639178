import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import {
  WIX_STORES as APP_DEFINITION_ID_STORES_OLD,
  WIX_NEW_STORES as APP_DEFINITION_ID_STORES_NEW,
  WIX_EVENTS as APP_DEFINITION_ID_EVENTS,
  WIX_RESTAURANTS_ORDERS as APP_DEFINITION_ID_RESTAURANTS,
  PRICING_PLANS as APP_DEFINITION_ID_PRICING_PLANS,
  WIX_BOOKINGS as APP_DEFINITION_ID_BOOKINGS,
} from '@wix/app-definition-ids';

const orderTriggerActivityTypesMap: Record<string, string[]> = {
  'stores/OrderPaid': [APP_DEFINITION_ID_STORES_OLD, APP_DEFINITION_ID_STORES_NEW],
  'wix-restaurants/orderSubmitted': [APP_DEFINITION_ID_RESTAURANTS],
  'events/OrderConfirmed': [APP_DEFINITION_ID_EVENTS],
  'pricingPlans/OrderPurchased': [APP_DEFINITION_ID_PRICING_PLANS],
  'bookings/BookingConfirmed': [APP_DEFINITION_ID_BOOKINGS],
};

export function calculateEarnPointsAmount(
  activeEarningRules: LoyaltyEarningRule[],
  checkout: Checkout,
  loyaltyAccount?: LoyaltyAccount,
): number {
  const orderTotalAmount = parseFloat(checkout.priceSummary?.total?.amount ?? '0');
  const userTierId = loyaltyAccount?.tier?.id;

  return activeEarningRules.reduce((earnPointsAmount, earningRule) => {
    const { triggerAppId, triggerActivityType } = earningRule;
    if (!triggerAppId || !triggerActivityType) {
      return earnPointsAmount;
    }

    const earningRuleAppDefIds = orderTriggerActivityTypesMap[triggerActivityType];
    if (!earningRuleAppDefIds || !earningRuleAppDefIds.includes(triggerAppId)) {
      return earnPointsAmount;
    }

    const hasRelevantLineItems = checkout.lineItems?.some(
      ({ catalogReference }) => !!catalogReference?.appId && earningRuleAppDefIds.includes(catalogReference.appId),
    );

    if (!hasRelevantLineItems) {
      return earnPointsAmount;
    }

    const { conversionRate, fixedAmount } = earningRule;

    if (fixedAmount) {
      const activeTierConfig = fixedAmount.configs?.find(
        ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
      );
      const ruleEarnPointsAmount = activeTierConfig?.points ?? 0;

      return earnPointsAmount + ruleEarnPointsAmount;
    } else if (conversionRate) {
      const activeTierConfig = conversionRate.configs?.find(
        ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
      );
      const moneyAmount = activeTierConfig?.moneyAmount ?? 0;
      const points = activeTierConfig?.points ?? 0;
      let ruleEarnPointsAmount = 0;

      if (moneyAmount && points) {
        ruleEarnPointsAmount = Math.floor(orderTotalAmount / moneyAmount) * points;
      }

      return earnPointsAmount + ruleEarnPointsAmount;
    }

    return earnPointsAmount;
  }, 0);
}
